<template>
  <div class="index">
    <starrySky />
    <Header>
      <div class="index-header flex-between">
        <span />
        <div class="tabxx">
          <span class="grail font-22" :class="{'g-acrive': routecur === 1}" @click="changeRoute(1)">大盘模式</span>
          <span class="title font-36">大数据监控系统</span>
          <span class="map font-22" :class="{'m-acrive': routecur === 2}" @click="changeRoute(2)">地图模式</span>
        </div>
        <dropdown trigger="click" @command="userClick">
          <span class="user-avatar">
            <img src="@/assets/user_info.png" alt="user">
          </span>
          <dropdown-menu class="mua-drapdown" slot="dropdown">
            <dropdown-item disabled>
              <span class="color-fff">{{user.user_name}}</span>
            </dropdown-item>
            <dropdown-item divided :disabled="!user.phone" :command="0">
              <span class="color-fff">修改密码</span>
            </dropdown-item>
            <dropdown-item :command="1">
              <span class="color-fff">退出</span>
            </dropdown-item>
          </dropdown-menu>
        </dropdown>
      </div>
    </Header>
    <div class="block"></div>
    <router-view />
  </div>
</template>

<script>
import {dropdown, dropdownMenu, dropdownItem} from 'element-ui'
import Header from '@/components/Header'
import starrySky from '@/components/starrySky/index'

export default {
  name: 'index',
  components: {
    dropdown,
    dropdownMenu,
    dropdownItem,
    Header,
    starrySky
  },
  data () {
    return {
      routecur: 1,
      user: global.store.get('DATA_USER')
    }
  },
  created() {
    const path = this.$route.path
    this.routecur = path.indexOf('map') !== -1 ? 2 : 1
  },
  methods: {
    userClick(command) {
      if (command === 0) {
        this.$router.push('/register')
      }
      if (command === 1) {
        this.$router.push('/login')
      }
    },
    changeRoute(index) {
      this.routecur = index
      if (index * 1 === 2) {
        this.$router.replace({path:'/map'})
      } else {
        this.$router.replace({path:'/grail'})
      }
    }
  }
}
</script>

<style lang="less" scoped>
.index {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
  &-header {
    width: 100%;
    .user-avatar {
      display: inline-block;
      width: 16px;
      height: 16px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .block {
    padding: 1.5rem 0;
  }
  .tabxx {
    color: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: top;
    position: relative;
    > span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .grail, .map {
      width: 217px;
      height: 60px;
      position: relative;
      cursor: pointer;
    }
    .title, .grail, .map {
      background-repeat: no-repeat;
      background-size: cover;
    }
    .title {
      width: 460px;
      height: 85px;
      background-image: url('../assets/title_bg.png');
    }
    .grail {
      left: 24px;
      background-image: url('../assets/left_bg.png');
    }
    .map {
      left: -27px;
      background-image: url('../assets/right_bg.png');
    }
    .g-acrive {
      background-image: url('../assets/left_active_bg.png');
    }
    .m-acrive {
      background-image: url('../assets/right_active_bg.png');
    }
  }
}
</style>
